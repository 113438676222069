import store from '@/store';

import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPage from '../views/DashboardPage.vue';
import LoginPage from '../views/LoginPage.vue';
import DashboardComponent from '../components/Dashboard/Home.vue';
import About from '../views/About.vue';

// Masterdata
import MasterDataDrugs from '../components/Master/MasterDataDrugs.vue';
import MasterDataGoods from '../components/Master/MasterDataGoods.vue';
import MasterDataStaff from '../components/Master/MasterDataStaff.vue';
import MasterDataProcedure from '../components/Master/MasterDataProcedure.vue';
import MasterDataDiagnose from '../components/Master/MasterDataDiagnose.vue';
import MasterDataUnit from '../components/Master/MasterDataUnit.vue';
import MasterDataServices from '../components/Master/MasterDataServices.vue';
import MasterDataBed from '../components/Master/MasterDataBed.vue';
import MasterDataSupplier from '../components/Master/MasterDataSupplier.vue';
import MasterDataLaboratory from '../components/Master/MasterDataLaboratory.vue';

// EMR
import EmrPage from '../components/EMR/EmrPage.vue';
import EmrDashboardPage from '../components/EMR/Component/EmrDashboardPage.vue';

// Billing
import Billing from '../components/Finance/Billing.vue';
import FinanceReport from '../components/Finance/FinanceReport.vue';
// import CashVerification from '../components/Finance/CashVerification.vue';
import BillingReport from '../components/Finance/Report/BillingReport.vue';
import IncomeReport from '../components/Finance/Report/IncomeReport.vue';
import PatientBilling from '../components/Finance/PatientBilling.vue';
import PatientBillingBPJS from '../components/Finance/PatientBillingBPJS.vue';
import CashPayment from '../components/Finance/CashPayment.vue';
import MedService from '../components/Finance/MedService.vue';
// import ReceivableReport from '../components/Finance/Report/ReceivableReport.vue';
// import CashReportView from '../components/Finance/CashReport/CashReportView.vue';
// import CashInput from '../components/Finance/CashInput.vue';
// import TransHistory from '../components/Finance/CashInput/TransHistory.vue';

//Accounting
import Accounting from '../components/Finance/Accounting.vue';
import AccountList from '../components/Finance/Accounting/AccountList.vue';
import JournalList from '../components/Finance/Accounting/Journal/JournalList.vue';
import Finance from '../components/Finance/Finance.vue';
import LedgerDashboard from '../components/Finance/Accounting/Ledger/LedgerDashboard.vue';
import SubsdiaryLedger from '../components/Finance/Accounting/Ledger/SubsdiaryLedger.vue';
import PostJournal from '../components/Finance/Accounting/Journal/PostJournal.vue';
import IncomeStatement from '../components/Finance/Accounting/FinancialReport/IncomeStatement.vue';
import FinancialPosition from '../components/Finance/Accounting/FinancialReport/FinancialPosition.vue';
import CashFlow from '../components/Finance/Accounting/FinancialReport/CashFlow.vue';
import EquityReport from '../components/Finance/Accounting/FinancialReport/EquityReport.vue';

// Service
import ServiceDashboard from '../components/Service/ServiceDashboard';
import IGDclinic from '../components/Service/IGDclinic.vue';
import VaccinePoly from '../components/Service/VaccinePoly.vue';
import InpatientDashboard from '../components/Service/InpatientDashboard.vue';
import DetailPatientQueue from '../components/Service/components/PatientQueue/DetailPatientQueue/DetailPatientQueue.vue';
import DashboardQueueForPatient from '../components/Service/components/PatientQueue/DashboardQueueForPatient/DashboardQueueForPatient.vue';

// Finance
import MedServicePayment from '../components/Finance/MedServicePayment/MedServicePayment.vue';

// Inventory
import ItemOrder from '../components/Inventory/ItemOrder.vue';
import StockOpname from '../components/Inventory/StockOpname.vue';
import ItemReceipt from '../components/Inventory/ItemReceipt.vue';
import StockInfo from '../components/Inventory/StockInfo.vue';
import StorageRequest from '../components/Inventory/StorageRequest.vue';
// import Mutation from '../components/Inventory/Mutation.vue';
// import StorageDelivery from '../components/Inventory/StorageDelivery.vue';
// import ReportingGoods from '../components/Inventory/ReportingGoods.vue';

//Pharmacy
import PharmacyMainMenu from '../components/Pharmacy/PharmacyMainMenu.vue';
import DrugToolsRequest from '../components/Pharmacy/Submenu/DrugToolsRequest.vue';
import DrugManufacturing from '../components/Pharmacy/Submenu/DrugManufacturing.vue';
import DrugHandover from '../components/Pharmacy/Submenu/DrugHandover.vue';
import DrugHandoverQueue from '../components/Pharmacy/Submenu/DrugHandoverQueue.vue';
import DrugQueue from '../components/Pharmacy/Submenu/DrugQueue.vue';
import OverTheCounter from '../components/Pharmacy/Submenu/OverTheCounter.vue';
import DashboardPharmacyQueueForPatient from '../components/Pharmacy/PharmacyQueue/PharmacyQueue.vue';

//Registration
import IGDRegist from '../components/Registration/IGDRegist.vue';
import DoctorSchedule from '../components/Registration/DoctorSchedule.vue';
import DataDoctorInput from '../components/Registration/components/DataDoctorInput.vue';
import QuotaSchedule from '../components/Registration/components/QuotaSchedule.vue';
import ScheduleChange from '../components/Registration/components/ScheduleChange.vue';
import OutpatientRegist from '../components/Registration/OutpatientRegist.vue';
import BedAvailability from '../components/Registration/BedAvailability.vue';
import InpatientRegist from '../components/Registration/InpatientRegist.vue';
import LaboratoriumRegist from '../components/Registration/LaboratoriumRegist.vue';

//Laboratorium
import ExaminationRequest from '../components/Laboratorium/ExaminationRequest.vue';

//Reporting
import ProlanisBPJSReport from '../components/Reporting/ServiceReport/ProlanisBPJSReport.vue';
import ReportingBOR from '../components/Reporting/ServiceReport/ReportingBOR.vue';
import ServicesReportDashboard from '../components/Reporting/ServicesReportDashboard.vue';
import MortalityReportDashboard from '../components/Reporting/MortalityReportDashboard.vue';
import ReportMortalityMother from '../components/Reporting/Mortality/ReportMortalityMother.vue';
import DoctorCompliance from '../components/Reporting/ServiceReport/DoctorCompliance.vue';
import PatientVisit from '../components/Reporting/ServiceReport/PatientVisit/PatientVisit.vue';
import MembershipDashboard from '../components/Reporting/ServiceReport/MembershipDashboard.vue';
import MorbidityReport from '../components/Reporting/MorbidityReport.vue';
import BPJSPatient from '../components/Reporting/ServiceReport/BPJSPatient.vue';
import MjknReportingDashboard from '../components/Reporting/MJKNReporting/MjknReportingDashboard.vue';
import BPJSPatientShow from '../components/Reporting/BPJSPatient/BPJSPatientShow.vue';
import GeneralConsentReport from '../components/Reporting/ServiceReport/GeneralConsentReport.vue';
import MonthlyReport from '../components/Reporting/ServiceReport/MonthlyReport.vue';
import DrugUsageReport from '../components/Reporting/ServiceReport/DrugUsageReport.vue';
import DinkesReport from '../components/Reporting/ServiceReport/DinkesReport.vue';
import PatientMortalityReport from '../components/Reporting/Mortality/PatientMortalityReport.vue';
import NeonatusMortalityReport from '../components/Reporting/Mortality/NeonatusMortalityReport.vue';
import ToddlerMortalityReport from '../components/Reporting/Mortality/ToddlerMortalityReport.vue';

// Setting
import PcarePassword from '../components/Setting/PcarePassword/PcarePassword.vue';
import IcarePassword from '../components/Setting/IcarePassword/IcarePassword.vue';

// Bridging
import DashboardSatuSehat from '../components/Bridging/SatuSehat/DashboardSatuSehat.vue';

// Restricted Page
import Restricted403 from '../components/Restricted/Restricted403.vue';
import Restricted404 from '../components/Restricted/Restricted404.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: DashboardPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'DashboardComponent',
        component: DashboardComponent,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },

      // MASTERDATA

      {
        path: '/masterdata/drugs',
        name: 'MasterDataDrugs',
        component: MasterDataDrugs,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.1',
        },
      },
      {
        path: '/masterdata/goods',
        name: 'MasterDataGoods',
        component: MasterDataGoods,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.2',
        },
      },
      {
        path: '/masterdata/staff',
        name: 'MasterDataStaff',
        component: MasterDataStaff,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.3',
        },
      },
      {
        path: '/masterdata/diagnosa',
        name: 'MasterDataDiagnosa',
        component: MasterDataDiagnose,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.4',
        },
      },
      {
        path: '/masterdata/prosedur',
        name: 'MasterDataProcedure',
        component: MasterDataProcedure,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.5',
        },
      },
      {
        path: '/masterdata/unit',
        name: 'MasterDataUnit',
        component: MasterDataUnit,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.6',
        },
      },
      {
        path: '/masterdata/layanan',
        name: 'MasterDataServices',
        component: MasterDataServices,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.7',
        },
      },
      {
        path: '/masterdata/supplier',
        name: 'MasterDataSupplier',
        component: MasterDataSupplier,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.8',
        },
      },
      {
        path: '/masterdata/bed',
        name: 'MasterDataBed',
        component: MasterDataBed,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.9',
        },
      },
      {
        path: '/masterdata/laboratory',
        name: 'MasterDataLaboratory',
        component: MasterDataLaboratory,
        props: true,
        meta: {
          requiresAuth: true,
          code: '1.10',
        },
      },

      // INVENTORY
      {
        path: '/inventaris/pemesanan',
        name: 'ItemOrder',
        component: ItemOrder,
        props: true,
        meta: {
          requiresAuth: true,
          code: '5.1',
        },
      },
      {
        path: '/inventaris/penerimaan',
        name: 'ItemReceipt',
        component: ItemReceipt,
        props: true,
        meta: {
          requiresAuth: true,
          code: '5.2',
        },
      },
      {
        path: '/inventaris/stok',
        name: 'StockInfo',
        component: StockInfo,
        props: true,
        meta: {
          requiresAuth: true,
          code: '5.3',
        },
      },
      {
        path: '/inventaris/permintaan-gudang',
        name: 'StorageRequest',
        component: StorageRequest,
        props: true,
        meta: {
          requiresAuth: true,
          code: '5.5',
        },
      },
      {
        path: '/inventaris/stok-opname',
        name: 'StockOpname',
        component: StockOpname,
        props: true,
        meta: {
          requiresAuth: true,
          code: '5.6',
        },
      },
      // {
      //   path: '/inventaris/pengiriman-gudang',
      //   name: 'StorageDelivery',
      //   component: StorageDelivery,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: '/inventaris/LaporanPemakaianBarang',
      //   name: 'ReportingGoods',
      //   component: ReportingGoods,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: '/inventaris/mutasi',
      //   name: 'Mutation',
      //   component: Mutation,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },

      // Reporting
      {
        path: '/reporting/laporan-pelayanan/pasien-prolanis',
        name: 'ProlanisBPJSReport',
        component: ProlanisBPJSReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },

      // EMR
      {
        path: '/emr',
        name: 'EmrPage',
        component: EmrPage,
        props: true,
        meta: {
          requiresAuth: true,
          code: '4',
        },
      },
      {
        path: '/emr/patient/:id',
        name: 'EmrDashboardPage',
        component: EmrDashboardPage,
        props: true,
        meta: {
          requiresAuth: true,
          code: '4',
        },
      },

      // Finance

      {
        path: '/keuangan/pembayaran-jasa-medis',
        name: 'MedServicdPayment',
        component: MedServicePayment,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      // Billing
      {
        path: '/keuangan/billing',
        name: 'Billing',
        component: Billing,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.1',
        },
      },
      {
        path: '/keuangan/billing/tagihan-pasien',
        name: 'PatientBilling',
        component: PatientBilling,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.1',
        },
      },
      {
        path: '/keuangan/billing/tagihan-pasien-bpjs',
        name: 'PatientBillingBPJS',
        component: PatientBillingBPJS,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.1',
        },
      },
      // {
      //   path: '/keuangan/billing/verifikasi-setor-kas',
      //   name: 'CashVerification',
      //   component: CashVerification,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //     code: '10.1',
      //   },
      // },
      {
        path: '/keuangan/laporan-keuangan',
        name: 'FinanceReport',
        component: FinanceReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.4',
        },
      },
      {
        path: '/keuangan/laporan-keuangan/laporan-pendapatan',
        name: 'IncomeReport',
        component: IncomeReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.4',
        },
      },
      {
        path: '/keuangan/laporan-keuangan/laporan-jasa-medis',
        name: 'MedService',
        component: MedService,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.4',
        },
      },
      {
        path: '/keuangan/billing/PembayaranTunai',
        name: 'CashPayment',
        component: CashPayment,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.1',
        },
      },
      {
        path: '/keuangan/billing/LaporanBilling',
        name: 'BillingReport',
        component: BillingReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.1',
        },
      },
      // {
      //   path: '/keuangan/laporan-keuangan/laporan-kas',
      //   name: 'CashReportView',
      //   component: CashReportView,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: '/keuangan/laporan-keuangan/rekapitulasi',
      //   name: 'ReceivableReport',
      //   component: ReceivableReport,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: '/keuangan/akuntansi',
        name: 'Accounting',
        component: Accounting,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/daftar-akun',
        name: 'AccountList',
        component: AccountList,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/daftar-jurnal',
        name: 'JournalList',
        component: JournalList,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/posting-jurnal',
        name: 'PostJournal',
        component: PostJournal,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/buku-besar',
        name: 'LedgerDashboard',
        component: LedgerDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/buku-besar-pembantu',
        name: 'SubsdiaryLedger',
        component: SubsdiaryLedger,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/laba-rugi',
        name: 'IncomeStatement',
        component: IncomeStatement,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/perubahan-ekuitas',
        name: 'EquityReport',
        component: EquityReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/posisi-keuangan',
        name: 'FinancialPosition',
        component: FinancialPosition,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/akuntansi/arus-kas',
        name: 'CashFlow',
        component: CashFlow,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.3',
        },
      },
      {
        path: '/keuangan/finance',
        name: 'Finance',
        component: Finance,
        props: true,
        meta: {
          requiresAuth: true,
          code: '10.2',
        },
      },

      // Service
      {
        path: '/pelayanan/poli-umum',
        name: 'ServiceDashboard',
        component: ServiceDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.1',
        },
      },
      {
        path: '/pelayanan/poli-gigi',
        name: 'ServiceDashboard',
        component: ServiceDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.3',
        },
      },
      {
        path: '/pelayanan/poli-KIA',
        name: 'ServiceDashboard',
        component: ServiceDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.2',
        },
      },
      {
        path: '/pelayanan/klinik-igd',
        name: 'IGDclinic',
        component: IGDclinic,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.4',
        },
      },
      {
        path: '/pelayanan/rawat-inap',
        name: 'InpatientDashboard',
        component: InpatientDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.5',
        },
      },
      {
        path: '/pelayanan/poli-imunisasi',
        name: 'VaccinePoly',
        component: VaccinePoly,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.6',
        },
      },
      {
        path: '/pelayanan/poli-fisioterapi',
        name: 'ServiceDashboard',
        component: ServiceDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.7',
        },
      },
      {
        path: '/pelayanan/poli-kb',
        name: 'ServiceDashboard',
        component: ServiceDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '3.8',
        },
      },

      // {
      //   path: '/keuangan/kas-masuk-keluar',
      //   name: 'CashInput',
      //   component: CashInput,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      // {
      //   path: '/keuangan/kas-masuk-keluar/riwayat-transaksi',
      //   name: 'TransHistory',
      //   component: TransHistory,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      //Pharmacy
      {
        path: '/farmasi/rawat-inap',
        name: 'PharmacyMainMenu',
        component: PharmacyMainMenu,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/rawat-jalan',
        name: 'PharmacyMainMenu',
        component: PharmacyMainMenu,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      {
        path: '/farmasi/igd',
        name: 'PharmacyMainMenu',
        component: PharmacyMainMenu,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.3',
        },
      },
      {
        path: '/farmasi/rawat-inap/permintaan-obat',
        name: 'DrugToolsRequest',
        component: DrugToolsRequest,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/rawat-inap/pembuatan-obat',
        name: 'DrugManufacturing',
        component: DrugManufacturing,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/rawat-inap/penyerahan-obat',
        name: 'DrugHandover',
        component: DrugHandover,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/rawat-inap/antrian-obat',
        name: 'DrugQueue',
        component: DrugQueue,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/rawat-inap/penjualan-obat-bebas',
        name: 'OverTheCounter',
        component: OverTheCounter,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.1',
        },
      },
      {
        path: '/farmasi/igd/permintaan-obat',
        name: 'DrugToolsRequest',
        component: DrugToolsRequest,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.3',
        },
      },
      {
        path: '/farmasi/igd/pembuatan-obat',
        name: 'DrugManufacturing',
        component: DrugManufacturing,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.3',
        },
      },
      {
        path: '/farmasi/igd/penyerahan-obat',
        name: 'DrugHandover',
        component: DrugHandover,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.3',
        },
      },
      {
        path: '/farmasi/rawat-jalan/permintaan-obat',
        name: 'DrugToolsRequest',
        component: DrugToolsRequest,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      {
        path: '/farmasi/rawat-jalan/pembuatan-obat',
        name: 'DrugManufacturing',
        component: DrugManufacturing,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      {
        path: '/farmasi/rawat-jalan/penyerahan-obat',
        name: 'DrugHandover',
        component: DrugHandoverQueue,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      {
        path: '/farmasi/rawat-jalan/antrian-obat',
        name: 'DrugQueue',
        component: DrugQueue,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      {
        path: '/farmasi/rawat-jalan/penjualan-obat-bebas',
        name: 'OverTheCounter',
        component: OverTheCounter,
        props: true,
        meta: {
          requiresAuth: true,
          code: '6.2',
        },
      },
      //Laboratorium
      {
        path: '/laboratorium/permintaan-pemeriksaan-lab',
        name: 'ExaminationRequest',
        component: ExaminationRequest,
        props: true,
        meta: {
          requiresAuth: true,
          code: '7',
        },
      },

      //Registration
      {
        path: '/pendaftaran/igd',
        name: 'IGDRegist',
        component: IGDRegist,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.2',
        },
      },
      {
        path: '/pendaftaran/jadwal-dokter',
        name: 'DoctorSchedule',
        component: DoctorSchedule,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.5',
        },
      },
      {
        path: '/pendaftaran/jadwal-dokter/pengaturan',
        name: 'DataDoctorInput',
        component: DataDoctorInput,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.5',
        },
      },
      {
        path: '/pendaftaran/jadwal-dokter/pengaturan/ubah-kuota',
        name: 'QuotaSchedule',
        component: QuotaSchedule,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.5',
        },
      },
      {
        path: '/pendaftaran/jadwal-dokter/pengaturan/ubah-jadwal',
        name: 'ScheduleChange',
        component: ScheduleChange,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.5',
        },
      },
      {
        path: '/pendaftaran/rawat-jalan',
        name: 'OutpatientRegist',
        component: OutpatientRegist,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.1',
        },
      },
      {
        path: '/pendaftaran/laboratorium',
        name: 'LaboratoriumRegist',
        component: LaboratoriumRegist,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.7',
        },
      },
      {
        path: '/pendaftaran/ketersediaan-bed',
        name: 'BedAvailability',
        component: BedAvailability,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.4',
        },
      },
      {
        path: '/pendaftaran/rawat-inap',
        name: 'InpatientRegist',
        component: InpatientRegist,
        props: true,
        meta: {
          requiresAuth: true,
          code: '2.3',
        },
      },

      //Reporting
      {
        path: '/reporting/laporan-pelayanan',
        name: 'ServicesReportDashboard',
        component: ServicesReportDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-morbiditas',
        name: 'MorbidityReport',
        component: MorbidityReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.2',
        },
      },
      {
        path: '/reporting/laporan-mortalitas',
        name: 'MortalityReportDashboard',
        component: MortalityReportDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.3',
        },
      },
      {
        path:
          '/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-ibu',
        name: 'ReportMortalityMother',
        component: ReportMortalityMother,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.3',
        },
      },
      {
        path:
          '/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-pasien',
        name: 'PatientMortalityReport',
        component: PatientMortalityReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.3',
        },
      },
      {
        path:
          '/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-neonatus',
        name: 'NeonatusMortalityReport',
        component: NeonatusMortalityReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.3',
        },
      },
      {
        path:
          '/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-balita',
        name: 'ToddlerMortalityReport',
        component: ToddlerMortalityReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.3',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/kepatuhan-resep',
        name: 'DoctorCompliance',
        component: DoctorCompliance,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/laporan-BOR',
        name: 'ReporingBOR',
        component: ReportingBOR,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/kunjungan-pasien',
        name: 'PatientVisit',
        component: PatientVisit,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/jumlah-pasien',
        name: 'MembershipDashboard',
        component: MembershipDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/pasien-rujukan-bpjs/detail',
        name: 'BPJSPatient',
        component: BPJSPatient,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/pasien-mjkn',
        name: 'MjknReportingDashboard',
        component: MjknReportingDashboard,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/pasien-rujukan-bpjs',
        name: 'BPJSPatientShow',
        component: BPJSPatientShow,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/laporan-bulanan',
        name: 'MonthlyReport',
        component: MonthlyReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/general-consent',
        name: 'GeneralConsent',
        component: GeneralConsentReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/10-besar-pemakaian-obat',
        name: 'DrugUsageReport',
        component: DrugUsageReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.1',
        },
      },
      {
        path: '/reporting/laporan-pelayanan/laporan-bulanan-dinas-kesehatan',
        name: 'DinkesReport',
        component: DinkesReport,
        props: true,
        meta: {
          requiresAuth: true,
          code: '9.2',
        },
      },

      // Setting
      {
        path: '/setting/password-pcare',
        name: 'PcarePassword',
        component: PcarePassword,
        props: true,
        meta: {
          requiresAuth: true,
          code: '11.1',
        },
      },
      {
        path: '/setting/password-icare',
        name: 'IcarePassword',
        component: IcarePassword,
        props: true,
        meta: {
          requiresAuth: true,
          code: '11.2',
        },
      },

      // Bridging
      {
        path: '/bridging/satusehat',
        name: 'SatuSehat',
        component: DashboardSatuSehat,
        props: true,
        meta: {
          requiresAuth: true,
          code: '12.1',
        },
      },
    ],
  },
  {
    path: '/login',
    component: LoginPage,
    props: true,
  },
  {
    path: '/pelayanan/:poly/antrean/:id_doctor',
    component: DetailPatientQueue,
    props: true,
    meta: {
      requiresAuth: true,
      code: 'free_authorized',
    },
  },
  {
    path: '/antrean-pasien',
    component: DashboardQueueForPatient,
    props: true,
    meta: {},
  },
  {
    path: '/antrean-farmasi',
    component: DashboardPharmacyQueueForPatient,
    props: true,
    meta: {},
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
  },
  {
    path: '/access-denied',
    props: true,
    component: Restricted403,
  },
  {
    path: '*',
    props: true,
    component: Restricted404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const freeAuthorized =
    to?.matched[1]?.path === '/' || to.meta?.code === 'free_authorized';
  if (to.matched.some(value => value.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
      });
    } else {
      if (freeAuthorized) {
        next();
        return;
      }
      const isAuthorized = !!store.getters.userLoggedIn.access.find(
        val => val === to.matched[1]?.meta?.code,
      );
      if (isAuthorized || !to.matched[1]?.meta?.code) {
        next();
      } else {
        next({
          path: '/access-denied',
        });
      }
    }
  } else if (to.path === '/login' && localStorage.getItem('token') != null) {
    next({
      path: from.path,
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
