<template>
  <div class="px-3 body-container">
    <v-row no-gutters class="row-style">
      <v-card class="list rounded-lg ml-0" flat>
        <v-row>
          <v-col cols="10">
            <v-row class="text-left pl-3">
              <span class="title pb-2">Daftar Obat Pasien</span>
            </v-row>
            <div v-if="drugs.list.length !== 0" class="text-sm-left mb-3">
              <span class="sub-title"
                >Diresepkan Oleh
                <span v-if="!loading.recipe" class="text-uppercase">{{
                  drugs.staff
                }}</span></span
              >
            </div>
          </v-col>
          <v-col class="text-start" cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="isSaved" fab text @click="refreshDrugs">
                  <font-awesome-icon
                    icon="sync-alt"
                    style="font-size: .8vw"
                    v-bind="attrs"
                    v-on="on"
                    :class="{ refresh: loading.recipe }"
                /></v-btn>
              </template>
              <span>Reload Stok</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <span
          v-if="!drugs.list.length && !loading.recipe"
          class="font-weight-medium grey--text pt-5 caption"
          >Tidak ada permintaan obat</span
        >
        <v-progress-linear
          class="mx-n5"
          indeterminate
          v-if="loading.recipe"
        ></v-progress-linear>
        <div v-if="!loading.recipe" class="pr-9">
          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                class="mb-2 drug-card"
                v-for="(item, index) in drugs.list"
                :key="item.id"
                @click="onClick(item.isRacik, index)"
              >
                <v-list-item-content
                  class="d-flex flex-row justify-space-between pt-0 pb-2"
                  style="height: 100%"
                >
                  <div
                    class="drug-card-title d-flex justify-space-between align-center"
                  >
                    <div class="text-start">
                      {{ index + 1 }}. {{ item?.name }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="red"
                            v-show="!item.isAvailable"
                            class="pl-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span
                          v-if="
                            item.isAvailable === false && item.isRacik === true
                          "
                          >Terdapat obat yang stoknya tidak cukup atau habis.
                          Silahkan pilih obat lainnya atau ubah jumlah yang
                          diberikannya
                        </span>
                        <span
                          v-else-if="
                            item.remainingStock !== 0 && item.isRacik === false
                          "
                        >
                          Stok obat tidak cukup, tersisa
                          {{ item.remainingStock }} stok. Silahkan pilih obat
                          lainnya atau ubah jumlah yang diberikan
                        </span>
                        <span
                          v-else-if="
                            item.remainingStock === 0 && item.isRacik === false
                          "
                          >Stok obat habis. Silahkan pilih obat lainnya</span
                        >
                      </v-tooltip>
                    </div>
                    <div class="d-flex flex-col">
                      <div
                        v-if="item.isAddOn"
                        class="addon-chip d-flex flex-col align-center"
                      >
                        <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                          >mdi-cash</v-icon
                        >
                        <label class="text-body-1">Tambahan</label>
                      </div>
                      <v-btn
                        icon
                        :disabled="isSaved"
                        @click.stop="deleteItem(item, index)"
                      >
                        <v-icon medium>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-row>
                    <v-col cols="9" class="py-2 text-left">
                      <span class="mr-3">{{ item.quantity || '' }}</span>
                      <span class="mr-2">•</span>
                      <span class="mr-3">{{ item.route || '-' }}</span>
                      <span class="mr-2">•</span>
                      <span class="mr-3">{{ item.usage || '-' }}</span>
                    </v-col>
                  </v-row>
                  <div v-show="isShow[index]" v-if="item.isRacik">
                    <v-divider class="py-1"></v-divider>
                    <div
                      v-for="(val, index) in item.drugs"
                      :key="index"
                      class="text-start"
                    >
                      <p style="font-size: 0.8vw; margin: 0;" class="py-1">
                        {{ index + 1 }}. {{ val.id_drugs?.name }}
                        {{ val.requested_quantity }}
                        <span
                          v-if="val.availability_stock === 0"
                          class="red--text"
                        >
                          Stok habis
                        </span>
                        <span
                          v-else-if="
                            val.availability_stock !== 0 &&
                              val.availability_stock < val.requested_quantity
                          "
                          class="red--text"
                        >
                          Stok tidak cukup
                        </span>
                      </p>
                    </div>
                  </div>
                  <span
                    v-if="item.isRacik"
                    text
                    dark
                    @click.prevent
                    @click="handleShow(index)"
                    class="text-capitalize mt-2 primary--text subtitle-2"
                  >
                    Lihat lebih {{ isShow[index] ? 'sedikit' : 'banyak' }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
      <pharmacy-drug-form ref="pharmacyDrugForm" />
    </v-row>
    <v-row>
      <drug-review
        v-if="!loading.recipe"
        @close-form="$emit('close-form')"
        @snackbar="val => $emit('snackbar', val)"
      />
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
        class="progress-circular"
        :size="50"
      ></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
import PharmacyDrugForm from './PharmacyDrugForm';
import DrugReview from './DrugReview';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  mixins: [alertMixin, formatMixin],
  components: {
    PharmacyDrugForm,
    DrugReview,
  },
  data() {
    return {
      hadReviewed: false,
      isNew: true,
      selectedItem: '',
      selectedData: '',
      idRecipe: '',
      isShow: [],
    };
  },
  computed: {
    ...mapGetters([
      'getRecipe',
      'getLoading',
      'getTotalPrice',
      'getForm',
      'getDrugReview',
    ]),
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isEdit;
      },
      set(value) {
        this.setForm({ label: 'isEdit', value });
      },
    },

    drugs: {
      get() {
        return this.getRecipe;
      },
      set(value) {
        this.isShow = new Array(value.list.length).fill(false);
      },
    },
    loading: {
      get() {
        return this.getLoading;
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getRecipeData();
    });
  },
  methods: {
    ...mapActions([
      'resolveGetDrugs',
      'resolveReloadRecipe',
      'resolveDeleteDrug',
    ]),
    ...mapMutations([
      'mapDrugDataToForm',
      'setForm',
      'checkStock',
      'clearState',
    ]),
    async refreshDrugs() {
      this.loading.recipe = true;
      await this.reloadRecipe();
      this.getRecipeData();
      this.$refs.pharmacyDrugForm.changeIsEditState();
    },
    deleteItem(drug) {
      Swal.fire({
        text: 'Apakah Anda Yakin Menghapus Obat ini?',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Lanjutkan',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        icon: 'warning',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.resolveDeleteDrug(drug.id);
            Swal.fire({
              icon: 'success',
              title: 'Obat berhasil dihapus',
              showConfirmButton: false,
              timer: 1100,
            });
            this.clearState('form');
            this.getRecipeData();
          } catch (error) {
            this.showErrorAxios(error);
          }
        }
      });
    },
    async reloadRecipe() {
      try {
        await this.resolveReloadRecipe();
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getRecipeData() {
      await this.resolveGetDrugs('requested');
    },
    onClick(isMix, index) {
      this.mapDrugDataToForm(index);
      this.checkStock(isMix);
      this.isEdit = true;
    },
    handleShow(index) {
      this.isShow[index] = !this.isShow[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.addon-chip {
  background-color: #fffbea;
  padding: 5px;
  color: #bb4802;
}

.body-container {
  margin-top: 1vw;
  width: 100%;
  .row-style {
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 30% 70%;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .refresh {
    animation: rotation 0.4s infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.list {
  margin-left: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 40vw;
  height: 32vw;
  .title {
    padding: 0.4vw;
    padding-left: 0;
    font-size: 1vw;
    color: #137bc0;
  }

  .sub-title {
    font-size: 0.9rem;
    color: grey;
  }

  .drug-card {
    min-height: 12.5vh;
    background-color: #f4f5f5;
    border-radius: 0.5vw;

    span {
      font-size: 0.8vw;
    }

    .drug-card-title {
      font-size: 0.87vw;
      font-weight: 500;
    }
  }
}
</style>
