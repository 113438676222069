<template>
  <v-container fluid>
    <v-snackbar top v-model="snackbar" timeout="2000" color="error">
      <div class="d-flex font-weight-bold justify-center body-1">
        Tidak ada Permintaan Obat
      </div>
    </v-snackbar>
    <v-card flat class="body-container">
      <v-row class="row-layouts px-10 pt-5">
        <v-col class="py-0 text-left font-weight-medium" cols="3">
          <span style="font-size: 1.3rem" class="primary--text"
            >Telaah Resep</span
          >
          <br />
          <span
            >Penelaah Resep :
            {{
              drugReview.recipeVerifier || $store.getters.userLoggedIn.name
            }}</span
          >
        </v-col>
        <v-col class="py-0 text-left font-weight-bold heading-6"> </v-col>
      </v-row>
      <v-row class="row-layouts px-10">
        <v-col cols="4">
          <div class="header-grid">
            <span class="text-left">Administratif</span>
            <span>Ya</span>
            <span class="text-left">Tidak</span>
          </div>
          <v-divider class="mb-3"></v-divider>
          <div
            v-for="(value, name, index) in drugReview.administrative"
            :key="'administrative-' + index"
            class="form-grid"
          >
            <span class="text-left">
              {{ resource.administrative[index] }}
            </span>
            <div class="mx-auto">
              <v-radio-group
                :readonly="
                  patient === 'Sudah diserahkan' ||
                    isSaved ||
                    drugReview.type !== 'requested'
                "
                v-model="drugReview.administrative[name]"
                class="pa-0 ma-0"
                row
                light
                hide-details
                dense
              >
                <v-radio
                  light
                  :ripple="false"
                  :value="true"
                  class="pa-0"
                ></v-radio>
                <v-radio
                  light
                  :ripple="false"
                  :value="false"
                  class="pa-0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="header-grid">
            <span class="text-left">Farmasetis</span>
            <span>Ya</span>
            <span class="text-left">Tidak</span>
          </div>
          <v-divider class="mb-3"></v-divider>
          <div
            v-for="(value, name, index) in drugReview.pharmaceuticals"
            :key="'pharmaceuticals-' + index"
            class="form-grid"
          >
            <span class="text-left">
              {{ resource.pharmaceuticals[index] }}
            </span>
            <div class="mx-auto">
              <v-radio-group
                :readonly="
                  patient === 'Sudah diserahkan' ||
                    isSaved ||
                    drugReview.type !== 'requested'
                "
                v-model="drugReview.pharmaceuticals[name]"
                class="pa-0 ma-0"
                row
                light
                hide-details
                dense
              >
                <v-radio
                  light
                  :ripple="false"
                  :value="true"
                  class="pa-0"
                ></v-radio>
                <v-radio
                  light
                  :ripple="false"
                  :value="false"
                  class="pa-0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="header-grid">
            <span class="text-left">Aspek Klinis</span>
            <span>Ya</span>
            <span class="text-left">Tidak</span>
          </div>
          <v-divider class="mb-3"></v-divider>
          <div
            v-for="(value, name, index) in drugReview.clinical"
            :key="'clinical-' + index"
            class="form-grid"
          >
            <span class="text-left">
              {{ resource.clinical[index] }}
            </span>
            <div class="mx-auto">
              <v-radio-group
                :readonly="
                  patient === 'Sudah diserahkan' ||
                    isSaved ||
                    drugReview.type !== 'requested'
                "
                v-model="drugReview.clinical[name]"
                class="pa-0 ma-0"
                row
                light
                hide-details
                dense
              >
                <v-radio
                  light
                  :ripple="false"
                  :value="true"
                  class="pa-0"
                ></v-radio>
                <v-radio
                  light
                  :ripple="false"
                  :value="false"
                  class="pa-0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row v-show="drugReview.type !== 'requested'" class="row-layouts px-10">
        <v-col class="text-left font-weight-bold heading-6 px-0">
          <label class="pl-3">
            Penelaah Obat :
            {{
              drugReview.drugsVerifier || $store.getters.userLoggedIn.name
            }}</label
          >
        </v-col>
      </v-row>

      <v-row
        v-show="drugReview.type !== 'requested'"
        class="row-layouts px-10"
        :class="{ 'mb-1': drugReview.type !== 'requested' }"
      >
        <v-col cols="4">
          <div class="header-grid">
            <span class="text-left">Telaah Obat</span>
            <span>Ya</span>
            <span class="text-left">Tidak</span>
          </div>
          <v-divider class="mb-3"></v-divider>
          <div
            v-for="(value, name, index) in drugReview.drugs_review"
            :key="'drugs_review-' + index"
            class="form-grid"
          >
            <span class="text-left">
              {{ resource.drugs_review[index] }}
            </span>
            <div class="mx-auto">
              <v-radio-group
                :readonly="patient === 'Sudah diserahkan' || isSaved"
                v-model="drugReview.drugs_review[name]"
                class="pa-0 ma-0"
                row
                light
                hide-details
                dense
              >
                <v-radio
                  light
                  :ripple="false"
                  :value="true"
                  class="pa-0"
                ></v-radio>
                <v-radio
                  light
                  :ripple="false"
                  :value="false"
                  class="pa-0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-row class="ma-0 pa-0 block-input">
            <v-col class="text-left" cols="3">
              <label class="font-weight-bold">Catatan</label>
            </v-col>
            <v-col cols="9">
              <v-textarea
                style="width: 300px"
                :readonly="
                  patient === 'Sudah diserahkan' ||
                    isSaved ||
                    drugReview.type !== 'requested'
                "
                class="line-long mt-0 pt-0"
                outlined
                flat
                rows="5"
                placeholder="Masukkan Catatan"
                hide-details
                :disabled="isReceive"
                v-model="drugReview.note"
                @change="onChange('note', 'checkList')"
              />
            </v-col>
            <v-col cols="6" class="pt-3 font-weight-bold text-left"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card-actions
      class="action-layout pt-5"
      :class="{
        requested: drugReview.type === 'requested',
        handover: drugReview.type !== 'requested',
      }"
    >
      <v-btn
        v-if="isSaved"
        depressed
        class="btn-entry text-none"
        @click="printTicket()"
      >
        Cetak E-Tiket Obat
      </v-btn>
      <v-spacer />
      <div class="price-box font-weight-reguler d-flex align-center">
        <div class="d-flex mr-4 align-center">
          <span class="mr-2">Total Tagihan</span>
          <v-btn icon dense class="ma-0" small>
            <v-tooltip color="#404040" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="openRecipeSummary"
                  v-bind="attrs"
                  v-on="on"
                  :size="20"
                  >mdi-arrow-top-right</v-icon
                >
              </template>
              <span class="text-left">Lihat ringkasan total harga</span>
            </v-tooltip>
          </v-btn>
        </div>
        <span class="price-text">
          {{ currency(totalDrugPrice) }}
        </span>
      </div>
      <div
        class="action-footer"
        v-if="drugReview.type === 'requested' && !isHasMedtool"
      >
        <v-btn
          @click="addRecipeReview"
          class="btn-create text-capitalize"
          tile
          depressed
          :disabled="isSaved"
          :loading="isLoading"
        >
          Simpan
        </v-btn>
      </div>
      <div
        class="action-footer"
        v-if="drugReview.type === 'requested' && isHasMedtool"
      >
        <v-btn outlined color="#137BC0" class="ml-3" depressed @click="next()">
          Selanjutnya
        </v-btn>
      </div>
      <div
        class="action-footer"
        v-if="
          drugReview.type !== 'requested' &&
            (patient.toLowerCase() === 'belum diserahkan' ||
              patient.toLowerCase() === 'antrean dilewati' ||
              patient.split(' ')[0].toLowerCase() === 'panggilan')
        "
      >
        <v-btn
          @click="addDrugReview"
          class="btn-create text-capitalize"
          tile
          depressed
          :loading="isLoading"
          :disabled="isSaved"
        >
          Simpan
        </v-btn>
      </div>
    </v-card-actions>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';
import PrintDrugsEticket from '@/mixin/printDrugsEticket';
import printMixin from '@/mixin/printMixin';
import { updatePatientPharmacy } from '@/fetchApi/Pharmacy';
import { createNamespacedHelpers } from 'vuex';
import PharmacyQueueMixin from '@/mixin/SocketMixin/PharmacyQueueMixin';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);
export default {
  name: 'DrugReview',
  mixins: [formatMixin, PrintDrugsEticket, printMixin, PharmacyQueueMixin],
  props: {
    patient_status: String,
    nonmix: Array,
    patient: Object,
    mix_recipe: Array,
    medtool: Array,
  },
  data() {
    return {
      snackbar: false,
      isLoading: false,
      resource: {
        administrative: ['Nama Pasien', 'Umur', 'Nama Dokter', 'Tanggal Resep'],
        pharmaceuticals: [
          'Nama Obat',
          'Bentuk Kekuatan',
          'Dosis',
          'Jumlah Obat',
          'Aturan Cara Penggunaan',
        ],
        clinical: [
          'Tepat Indikasi',
          'Tepat Dosis',
          'Tepat Waktu Penggunaan',
          'Duplikasi Obat',
          'Alergi',
          'ROTD',
        ],
        drugs_review: [
          'Benar Pasien',
          'Benar Obat',
          'Benar Dosis',
          'Benar Cara Pemberian',
          'Benar Waktu Pemberian',
          'Tidak ED',
        ],
      },
      isReceive: false,
    };
  },
  computed: {
    ...mapGetters([
      'getDrugReview',
      'getPatientData',
      'getRecipe',
      'getTotalPrice',
      'getMedTools',
    ]),
    isHasMedtool: {
      get() {
        return this.getMedTools?.item?.length !== 0;
      },
    },
    totalDrugPrice: {
      get() {
        return this.getTotalPrice;
      },
    },
    recipe: {
      get() {
        return this.getRecipe;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    idPharmacy: {
      get() {
        return this.getPatientData.idPharmacy;
      },
    },
    drugReview: {
      get() {
        return this.getDrugReview.items;
      },
    },
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
      set(value) {
        this.setDrugReview({ label: 'isSaved', value });
      },
    },
    isDrugsComplete() {
      return this.drugReview.type !== 'requested'
        ? this.nonmix?.every(item => item.status) &&
            this.mix_recipe?.every(item => item.status) &&
            this.medtool?.every(item => item.status)
        : undefined;
    },
  },
  methods: {
    ...mapActions(['resolveGetDrugs']),
    ...mapMutations([
      'setDrugReview',
      'setMenuSelected',
      'setDialog',
      'mapToRecipeSummary',
    ]),
    next() {
      this.setMenuSelected(1);
    },
    printTicket() {
      const data_patient = {
        name: this.patientData.name,
        rmNumber: this.patientData.rmNumber,
        birthDate: this.patientData.birthDate,
      };
      const doc = this.printEticket(data_patient, this.recipe.list);

      this.printDocument(doc);
    },
    async addRecipeReview() {
      this.isLoading = true;
      try {
        if (this.recipe.total == 0) {
          this.snackbar = true;
          return;
        }
        const payload = {
          status: {
            type: 'production',
            id_staff: this.drugReview.id_staff,
          },
          administrative: this.drugReview.administrative,
          pharmaceutical: this.drugReview.pharmaceuticals,
          clinical: this.drugReview.clinical,
          recipe_verifier: this.$store.getters.userLoggedIn.id,
        };
        const response = await updatePatientPharmacy({
          payload,
          id: this.idPharmacy,
        });
        if (response.status === 200) {
          this.isSaved = true;
          Swal.fire('Data Permintaan Obat dan Alkes Tersimpan', '', 'success');
        }
        // this.$emit('snackbar', {
        //   show: true,
        //   color: '#2B9C4D',
        //   text: 'Permintaan obat berhasil disimpan',
        // });
      } catch (error) {
        const { pesan } = error.response.data;
        const messageData = pesan.replace('Bad Request: ', '');
        const showMessage = messageData.split(',');
        Swal.fire({
          title: `<small>${showMessage[0]}</small>`,
          text: `${showMessage[1]}${
            showMessage[2] ? `, ${showMessage[2]}` : ''
          }`,
          icon: 'error',
        });
        await this.resolveGetDrugs('requested');
        this.$emit('snackbar', {
          show: true,
          color: '#C62828',
          text: 'Permintaan obat gagal disimpan',
        });
      } finally {
        this.isLoading = false;
        // this.$emit('close-form');
      }
    },
    openRecipeSummary() {
      this.mapToRecipeSummary();
      this.setDialog({ key: 'recipeSummary', value: true });
    },
    addDrugReview() {
      this.isLoading = true;
      if (!this.isDrugsComplete) {
        Swal.fire({
          text:
            'Terdapat obat yang belum diserahkan, Apakah Anda yakin akan melanjutkan?',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Lanjutkan',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
          reverseButtons: true,
        }).then(async result => {
          if (result.isConfirmed) {
            this.putDrugReview();
          }
        });
      } else {
        this.putDrugReview();
        this.$emit('finish');
        this.emitMessageCall();
      }
    },

    // for drug handover
    async putDrugReview() {
      try {
        const recipe = this.nonmix.map(nonRacik => {
          return {
            _id: nonRacik.id,
            isApproved: true,
            isCreated: true,
            isDelivered: true,
            isOrdered: true,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        const mix_recipe = this.mix_recipe.map(racik => {
          return {
            _id: racik.id,
            name: racik.name,
            isOrdered: true,
            isApproved: true,
            isCreated: true,
            isDelivered: true,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });

        const medical_tools = this.medtool.map(alkes => {
          return {
            name: alkes.name,
            _id: alkes.id,
            isApproved: true,
            isCreated: true,
            isDelivered: true,
            isOrdered: true,
            staff_create: this.$store.getters.userLoggedIn.name,
          };
        });
        const payload = {
          status: {
            type: 'finish',
            id_staff: this.drugReview.id_staff,
          },
          recipe: recipe,
          mix_recipe: mix_recipe,
          medical_tools: medical_tools,
          patient_status: this.patient_status,
          drugs_review: this.drugReview.drugs_review,
          description: this.drugReview.note,
          drugs_verifier: this.$store.getters.userLoggedIn.id,
        };
        const response = await updatePatientPharmacy({
          payload,
          id: this.idPharmacy,
        });
        if (response.status === 200 || response.status === 204) {
          this.isLoading = false;
          if (this.drugReview.type === 'requested') {
            this.isSaved = true;
          }
          this.$emit('close-form');
          this.$emit('show-snackbar');
        }
      } catch (error) {
        this.isLoading = false;
        if (error?.response?.status === 400) {
          Swal.fire({
            icon: 'warning',
            title: 'Terdapat Obat yang tidak cukup atau habis',
            text: 'Silahkan cek kembali dan pilih obat lainnya',
            confirmButtonText: 'Kembali',
          });
          this.$emit('reload-recipe');
          return;
        }
        const { pesan } = error.response.data;
        const messageData = pesan.replace('Bad Request: ', '');
        const showMessage = messageData.split(',');
        Swal.fire({
          title: `<small>${showMessage[0]}</small>`,
          text: `${showMessage[1]}${
            showMessage[2] ? `, ${showMessage[2]}` : ''
          }`,
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: left;
  padding-left: 35px;
}

.header-grid {
  display: grid;
  grid-template-columns: 3fr repeat(2, 0.5fr);
  font-size: 0.8vw;
  font-weight: 600;
}

.form-grid {
  display: grid;
  grid-template-columns: 3.3fr 1fr;
  font-size: 0.8vw;
  height: 1.9vw;
}

.price-box {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.9rem;

  .price-text {
    font-weight: 600;
    color: #1565c0;
  }
}

.body-container {
  margin-top: 10px;
  margin-bottom: 5vw;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .row-layouts {
    width: 100%;
  }
}
.action-layout {
  display: flex;
  &.requested {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 0.8rem 1rem;
  }
  &.handover {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 0.8rem 1rem;
  }
  .btn-entry {
    border: 2px solid #3498db;
    color: #3498db;
    outline: cadetblue;
    background-color: white !important;
  }
  .action-footer {
    justify-content: end;
    align-items: end;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .btn-create {
      margin: 0 20px;
      max-width: 80px;
      border-radius: 4px;
      height: 30px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;

      &.next {
        max-width: 120px;
      }
    }
    .btn-entry {
      border: 2px solid #3498db;
      color: #3498db;
      outline: cadetblue;
      background-color: white !important;
    }
  }
}
</style>
