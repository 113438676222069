<template>
  <v-card>
    <v-container fluid>
      <div class="sticky">
        <div class="d-flex justify-space-between align-center px-4">
          <h2>Permintaan Obat dan Alkes</h2>
          <v-btn text fab @click="$emit('close-form')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <pharmacy-header />
        <v-tabs v-model="menuSelected" centered grow>
          <v-tab>
            <label class="text-none font-weight-bold"> Permintaan Obat </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              Permintaan Alkes
            </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              Histori Permintaan dan Pemberian Obat
            </label>
          </v-tab>
        </v-tabs>
      </div>
      <div class="scrollable-area">
        <v-tabs-items v-model="menuSelected">
          <v-tab-item>
            <drug-request
              v-if="recipeData.id"
              @close-form="closeDialogForm"
              @snackbar="val => $emit('snackbar', val)"
            />
          </v-tab-item>
          <v-tab-item>
            <v-row no-gutters>
              <medtool-request
                @close-form="closeDialogForm"
                @snackbar="val => $emit('snackbar', val)"
              />
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters style="height: 65vh">
              <drug-history />
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-dialog v-model="dialog" :max-width="900">
        <recipe-summary />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DrugRequest from './component/DrugRequest';
import DrugHistory from './component/DrugHistory';
import MedtoolRequest from './component/MedtoolRequest';
import RecipeSummary from './component/RecipeSummary.vue';
// import Skeleton from '../../../SharedComponent/Skeleton.vue';
import PharmacyHeader from '../HeaderInfo.vue';
import alertMixin from '@/mixin/alertMixin';

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

const {
  mapActions: mapActionsHeader,
  mapMutations: mapMutationsHeader,
} = createNamespacedHelpers('HeaderPharmacyStore');

export default {
  components: {
    DrugRequest,
    DrugHistory,
    MedtoolRequest,
    // Skeleton,
    RecipeSummary,
    PharmacyHeader,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  mixins: [alertMixin],
  data() {
    return {
      expanded: false,
      recipeReviewed: false,
      recipe: {},
      data: [],
      drug: [],
    };
  },
  computed: {
    ...mapGetters([
      'getMenuSelected',
      'getPatientData',
      'getLoading',
      'getRecipe',
      'getDialog',
    ]),
    menuSelected: {
      get() {
        return this.getMenuSelected;
      },
      set(value) {
        this.setMenuSelected(value);
      },
    },
    recipeData: {
      get() {
        return this.getRecipe;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    loading: {
      get() {
        return this.getLoading.pharmacy;
      },
    },
    dialog: {
      get() {
        return this.getDialog.recipeSummary;
      },
    },
  },
  beforeDestroy() {
    // Clear all state in store modules
    this.clearState('recipe');
    this.clearState('patient');
    this.clearState('price');
    this.clearState('history');
    this.clearState('review');
    this.clearState('medtool');
    this.clearState('menu');
    this.clearStateHeader();
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatientPharmacyData();
      this.getAllergyPatient();
      this.getHeaderInformation();
    });
  },
  methods: {
    ...mapActions(['resolveGetPharmacyPatientData']),
    ...mapActionsHeader([
      'resolveGetAllergiesPatient',
      'resolveGetPatientData',
    ]),
    ...mapMutations(['clearState', 'setMenuSelected']),
    ...mapMutationsHeader(['clearStateHeader']),
    async getPatientPharmacyData() {
      try {
        await this.resolveGetPharmacyPatientData(this.patient.id);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getAllergyPatient() {
      try {
        await this.resolveGetAllergiesPatient(this.patient.id_emr);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getHeaderInformation() {
      try {
        await this.resolveGetPatientData(this.patient.id_registration);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    closeDialogForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.allergies {
  text-align: start;

  .allergy {
    margin-right: 1.3vw;
  }

  label {
    color: #9e9e9e;
  }
  p {
    color: #404040;
  }
}
.sticky {
  width: 100%;
  background-color: #ffffff !important;
}

.scrollable-area {
  height: 75vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
</style>
